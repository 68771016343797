import(/* webpackMode: "eager", webpackExports: ["ScrollNextButton"] */ "/vercel/path0/apps/freim/src/ui/actions/ScrollNextButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Swipe"] */ "/vercel/path0/apps/freim/src/ui/indicators/Swipe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/framer-motion/dist/es/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["useObservable","useObservableEvent"] */ "/vercel/path0/node_modules/react-rx/dist/index.js");
